import styles from './component.module.scss';

const Input = ({
  label,
  value,
  onChange
}: {
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <div className={styles.main}>
      <label>{label}</label>
      <input value={value} onChange={onChange} />
    </div>
  );
};

export { Input };
