import styles from './component.module.scss';

const Textarea = ({
  label,
  value,
  onChange
}: {
  label: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}) => {
  return (
    <div className={styles.main}>
      <label>{label}</label>
      <textarea value={value} onChange={onChange}></textarea>
    </div>
  );
};

export { Textarea };
