import { useState } from 'react';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

import { Button, Input, Textarea } from '../index.ts';

import styles from './component.module.scss';

const ContactUs = () => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');

  const doSubmit = async () => {
    notifications.show({
      autoClose: 2000,
      color: 'teal',
      withBorder: true,
      loading: true,
      title: 'Enviando solicitud',
      message: '...'
    });
    try {
      await axios.post(
        'https://d4ve5httg5.execute-api.ca-central-1.amazonaws.com/contacts/message',
        { name: fullName, email, msg: message }
      );
      notifications.show({
        color: 'teal',
        withBorder: true,
        title: 'Correo de confirmación',
        message: 'Se le ha enviado un correo con un link de confirmación'
      });
    } catch (error) {
      notifications.show({
        withBorder: true,
        color: 'red',
        title: 'Error',
        message: 'Ha habido un error'
      });
    }
  };

  return (
    <div className={styles.contactus}>
      <div className={styles.card}>
        <h1 className={styles.titulo4}>CONTACT US</h1>
        <div className={styles.form}>
          <div className={styles.container}>
            <Input
              label="Full name"
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
            />
            <Input
              label="E-mail"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Button onClick={doSubmit}>Contact us</Button>
          </div>
          <Textarea
            label="Message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          ></Textarea>
        </div>
      </div>
    </div>
  );
};

export { ContactUs };
