// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y7PvthflD9iMNZYjG_H3{display:flex;min-width:225px;justify-content:center;align-items:center;padding:7px 44px;background-color:rgba(100,100,100,.86);outline:none;border:none;cursor:pointer;border-radius:24px;color:#fff;text-align:center;font-family:Play;font-size:28.75px;font-style:normal;font-weight:400;line-height:normal}", "",{"version":3,"sources":["webpack://./src/components/Button/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CAEA,sBAAA,CACA,kBAAA,CACA,gBAAA,CACA,sCAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CACA,kBAAA,CAEA,UAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".button {\n  display: flex;\n  min-width: 225px;\n\n  justify-content: center;\n  align-items: center;\n  padding: 7px 44px;\n  background-color: rgba(#646464, 0.86);\n  outline: none;\n  border: none;\n  cursor: pointer;\n  border-radius: 24px;\n\n  color: #fff;\n  text-align: center;\n  font-family: Play;\n  font-size: 28.75px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "y7PvthflD9iMNZYjG_H3"
};
export default ___CSS_LOADER_EXPORT___;
