// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I8H4BOrk86_Vce5qz_7q{display:flex;width:390px;flex-flow:column wrap;color:#000;font-family:Play;font-size:20px;font-style:normal;font-weight:400;line-height:normal}.I8H4BOrk86_Vce5qz_7q input{width:100%;background:none;outline:none;border:none;border-bottom:2px solid #000;color:#000;font-family:Play;font-size:20px;font-style:normal;font-weight:400;line-height:normal}", "",{"version":3,"sources":["webpack://./src/components/Input/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CAEA,qBAAA,CAGA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,4BACE,UAAA,CAEA,eAAA,CACA,YAAA,CACA,WAAA,CACA,4BAAA,CAGA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".main {\n  display: flex;\n  width: 390px;\n\n  flex-flow: column wrap;\n\n\n  color: #000;\n  font-family: Play;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n\n  input{\n    width: 100%;\n\n    background:none;\n    outline: none;\n    border: none;\n    border-bottom: 2px solid #000;\n   \n\n    color: #000;\n    font-family: Play;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "I8H4BOrk86_Vce5qz_7q"
};
export default ___CSS_LOADER_EXPORT___;
