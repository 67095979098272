// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j1C4ySyEbPMOTFzQBO8B{display:flex;height:406px;width:100%;background:rgba(140,140,140,.55);backdrop-filter:blur(6px);justify-content:center}.JPUqQ4r9X0oZluY_gkOg{display:flex;color:#000;flex-direction:column;justify-content:center;row-gap:30px;font-size:16px;font-style:normal;font-weight:400;line-height:normal}.JPUqQ4r9X0oZluY_gkOg a{display:flex;align-items:center;column-gap:27px;text-decoration:none;color:#000}", "",{"version":3,"sources":["webpack://./src/components/Footer/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,UAAA,CACA,gCAAA,CACA,yBAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CAEA,qBAAA,CACA,sBAAA,CAEA,YAAA,CAEA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,wBACE,YAAA,CACA,kBAAA,CACA,eAAA,CACA,oBAAA,CACA,UAAA","sourcesContent":[".footer {\n  display: flex;\n  height: 406px;\n  width: 100%;\n  background: rgba(140, 140, 140, 0.55);\n  backdrop-filter: blur(6px);\n  justify-content: center;\n}\n\n.contactList {\n  display: flex;\n  color: #000000;\n\n  flex-direction: column;\n  justify-content: center;\n\n  row-gap: 30px;\n\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n\n  a {\n    display: flex;\n    align-items: center;\n    column-gap: 27px;\n    text-decoration: none;\n    color: #000000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "j1C4ySyEbPMOTFzQBO8B",
	"contactList": "JPUqQ4r9X0oZluY_gkOg"
};
export default ___CSS_LOADER_EXPORT___;
