import React from 'react';

import styles from './component.module.scss';

const Button = ({
  children,
  onClick
}: {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {children}
    </button>
  );
};

export { Button };
