import gmail from '../../assets/gmail.svg';
import github from '../../assets/github.svg';
import instagram from '../../assets/instagram.svg';
import LinkedIn from '../../assets/LinkedIn.svg';

import styles from './component.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <nav className={styles.contactList}>
        <a href="mailto://juliandavid12327@gmail.com" target="_blank">
          <img src={gmail} alt="gmail" />
          <span>juliandavid12327@gmail.com</span>
        </a>
        <a href="https://github.com/JulianDavidRG" target="_blank">
          <img src={github} alt="github" />
          <span>JulianDavidRG</span>
        </a>
        <a href="https://www.instagram.com/julian_03da/" target="_blank">
          <img src={instagram} alt="instagram" />
          <span>julian03_da</span>
        </a>
        <a href="https://www.linkedin.com/in/juliandavidaranea" target="_blank">
          <img src={LinkedIn} alt="LinkedIn" />
          <span>juliandavidaranea</span>
        </a>
      </nav>
    </footer>
  );
};

export { Footer };
