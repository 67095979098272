// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BgBWLHu89irSD5hoxBhu{display:flex;height:70px;width:100%;top:0;left:0;position:fixed;flex-direction:row;align-items:center;justify-content:center;background:rgba(140,140,140,.55);backdrop-filter:blur(4.5px);z-index:100}.yYL2VxIDt4hrGAikybbi{height:47px;width:21px;position:absolute;top:12px;left:41px;justify-content:left;align-items:center}.Nzrp8fLAmA4G2wjuTWTH{display:flex;color:#000;text-align:center;justify-content:space-between;font-size:16px;font-style:normal;font-weight:400;line-height:normal;width:644px}", "",{"version":3,"sources":["webpack://./src/components/Header/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,UAAA,CACA,KAAA,CACA,MAAA,CAEA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,gCAAA,CACA,2BAAA,CACA,WAAA,CAGF,sBACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,QAAA,CACA,SAAA,CACA,oBAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,UAAA,CACA,iBAAA,CACA,6BAAA,CAEA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".header {\n  display: flex;\n  height: 70px;\n  width: 100%;\n  top: 0;\n  left: 0;\n\n  position: fixed;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  background: rgba(140, 140, 140, 0.55);\n  backdrop-filter: blur(4.5px);\n  z-index: 100;\n}\n\n.logo {\n  height: 47px;\n  width: 21px;\n  position: absolute;\n  top: 12px;\n  left: 41px;\n  justify-content: left;\n  align-items: center;\n}\n\n.list {\n  display: flex;\n  color: #000;\n  text-align: center;\n  justify-content: space-between;\n\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  width: 644px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "BgBWLHu89irSD5hoxBhu",
	"logo": "yYL2VxIDt4hrGAikybbi",
	"list": "Nzrp8fLAmA4G2wjuTWTH"
};
export default ___CSS_LOADER_EXPORT___;
