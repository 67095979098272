import './App.scss';
import BackgroundImage from './assets/background.jpg';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import compu from './assets/compu.svg';
import front from './assets/front.svg';
import lapiz from './assets/lapiz.svg';
import { ContactUs, Footer, Header } from './components/index.ts';

function App() {
  return (
    <div className="App">
      <div
        className="Background"
        style={{ backgroundImage: `url("${BackgroundImage}")` }}
      ></div>
      <Header />
      <div className="App-body">
        <div className="Home">
          <h1 className="titulo1">ENGINEER & DEVELOPER</h1>
          <p>
            I am an electrical engineer who wanted to expand his knowledge to
            the web developer environment,<br></br> specializing in front-end
            and back-end. My base program is VS code for its different
            advantages and facilities to write code whatever the technologies
            used.
          </p>
        </div>

        <div className="experience">
          <h1 className="titulo2">MY EXPERIENCE</h1>
          <h2 className="h2">I HAVE IMPLEMENTED THECNOLOGIES AS: </h2>
          <div className="cards">
            <div className="card">
              <h3>
                <img src={compu} alt="compu" /> Software Development
              </h3>
              <p>Experience in Pyton, Java, JavaScript, TypeScript</p>
            </div>
            <div className="card">
              <h3>
                <img src={front} alt="front" />
                Front End And Back End
              </h3>
              <p>Experience in HTML, CSS, JS, React and NextJS frameworks.</p>
            </div>
            <div className="card">
              <h3>
                <img src={lapiz} alt="lapiz" />
                Designer Bases
              </h3>
              <p>
                Experience in planning and creating designs for my own projects.
              </p>
            </div>
          </div>
        </div>

        <div className="work">
          <h1 className="titulo3">MY WORK</h1>
          <div className="container">
            <img src={img1} alt="img1" />
            <p>
              In my time as a developer I have managed to make works like my
              portfolio and different<br></br> APIs to implement to different
              projects. All this using VScode and TypesScript as programming
              language along with React and Node js.
            </p>
          </div>
        </div>

        <div className="about">
          <div className="container">
            <h1 className="titulo4">
              HELLO I’M JULIAN<br></br>RODRIGUEZ
            </h1>
            <p>
              I am a developer in the process of learning in order to obtain all
              the necessary knowledge<br></br> to be able to develop much more
              sophisticated projects and become part of the programming world.
            </p>
          </div>
          <img src={img2} alt="img2" />
        </div>

        <ContactUs></ContactUs>
      </div>

      <Footer />
    </div>
  );
}

export default App;
