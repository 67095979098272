import logo from '../../assets/logo.svg';

import styles from './component.module.scss';

const Header = () => {
  return (
    <header className={styles.header}>
      <img src={logo} className={styles.logo} alt="logo" />

      <nav className={styles.list}>
        <a>HOME</a>
        <a>WORKS</a>
        <a>ABOUT</a>
        <a>CONTACT</a>
      </nav>
    </header>
  );
};

export { Header };
