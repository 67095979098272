// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GVhJPoxk1kxRNVoe63NC{display:flex;width:100%;height:100vh;justify-content:center;align-items:center}.GVhJPoxk1kxRNVoe63NC .crr0xqcokDRfQDxYMAiL{display:flex;width:1072px;height:586px;flex-direction:column;padding:53px 76px;row-gap:32px;background:rgba(217,217,217,.71);backdrop-filter:blur(2.5px)}.GVhJPoxk1kxRNVoe63NC .wE6KfF7WslC6Pl5X6Hco{margin:0;color:#000;font-family:Playfair Display SC;font-size:86.921px;font-weight:400}.GVhJPoxk1kxRNVoe63NC .NS8CoR2pBw15prJkLaNn{display:flex;width:100%;flex-direction:row;column-gap:140px}.GVhJPoxk1kxRNVoe63NC .fQAeF5HwPgtKiGEOV96K{display:flex;flex-direction:column;justify-content:space-around;align-items:center;row-gap:50px}", "",{"version":3,"sources":["webpack://./src/components/ContactUs/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4CACE,YAAA,CACA,YAAA,CACA,YAAA,CAEA,qBAAA,CACA,iBAAA,CACA,YAAA,CACA,gCAAA,CACA,2BAAA,CAGF,4CACE,QAAA,CACA,UAAA,CAEA,+BAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,YAAA,CACA,UAAA,CAEA,kBAAA,CACA,gBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,4BAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".contactus {\n  display: flex;\n  width: 100%;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n\n  .card {\n    display: flex;\n    width: 1072px;\n    height: 586px;\n\n    flex-direction: column;\n    padding: 53px 76px;\n    row-gap: 32px;\n    background: rgba(217, 217, 217, 0.71);\n    backdrop-filter: blur(2.5px);\n  }\n\n  .titulo4 {\n    margin: 0;\n    color: #000;\n\n    font-family: Playfair Display SC;\n    font-size: 86.921px;\n    font-weight: 400;\n  }\n\n  .form {\n    display: flex;\n    width: 100%;\n\n    flex-direction: row;\n    column-gap: 140px;\n  }\n  \n  .container {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    row-gap: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactus": "GVhJPoxk1kxRNVoe63NC",
	"card": "crr0xqcokDRfQDxYMAiL",
	"titulo4": "wE6KfF7WslC6Pl5X6Hco",
	"form": "NS8CoR2pBw15prJkLaNn",
	"container": "fQAeF5HwPgtKiGEOV96K"
};
export default ___CSS_LOADER_EXPORT___;
