// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".brZhGvr3cNtCxNaFosJQ{display:flex;flex-direction:column;font-family:Play;font-size:19.847px;font-style:normal;font-weight:400;line-height:normal}.brZhGvr3cNtCxNaFosJQ textarea{width:389px;height:278.849px;background:none;outline:none;border:2px solid #000;resize:none;font-family:Play;font-size:19px}", "",{"version":3,"sources":["webpack://./src/components/Textarea/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,gBAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,+BACE,WAAA,CACA,gBAAA,CAEA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n\n  font-family: Play;\n  font-size: 19.847px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n\n  textarea {\n    width: 389px;\n    height: 278.849px;\n\n    background: none;\n    outline: none;\n    border: 2px solid #000;\n    resize: none;\n    font-family: Play;\n    font-size: 19px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "brZhGvr3cNtCxNaFosJQ"
};
export default ___CSS_LOADER_EXPORT___;
